import { Auth, UserInfo as FirebaseUser } from 'firebase/auth'

export const useUserStore = defineStore('user', () => {
  const user = ref(
    useLocalStorage('wolfpack-user-local-storage', {}) as unknown as
      | FirebaseUser
      | Record<string, never>
  )

  const auth = ref<Auth>()
  const isUserLoggedIn = ref(false)

  return {
    auth,
    isUserLoggedIn,
    user
  }
})
