<template>
  <div class="min-h-screen bg-softlight-500">
    <AppHeader />
    <div class="mx-auto max-w-[1768px] px-5 pb-5">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </div>
  </div>
</template>
