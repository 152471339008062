import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_X7whXyrIWR from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import vueMaterialDesignIcons_hFYT2L7TZ2 from "/opt/atlassian/pipelines/agent/build/plugins/vueMaterialDesignIcons.ts";
import primeVue_d9qEuQH3r1 from "/opt/atlassian/pipelines/agent/build/plugins/primeVue.js";
import sentry_PZVGm6LH3G from "/opt/atlassian/pipelines/agent/build/plugins/sentry.js";
import veeValidate_U1l0qzVbBC from "/opt/atlassian/pipelines/agent/build/plugins/veeValidate.ts";
import vueDatepicker_xPbZ7qTVbL from "/opt/atlassian/pipelines/agent/build/plugins/vueDatepicker.ts";
import vueDompurifyHtml_PZ39sa3f1y from "/opt/atlassian/pipelines/agent/build/plugins/vueDompurifyHtml.js";
import vueToastification_TGgIjy9mOG from "/opt/atlassian/pipelines/agent/build/plugins/vueToastification.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  vueMaterialDesignIcons_hFYT2L7TZ2,
  primeVue_d9qEuQH3r1,
  sentry_PZVGm6LH3G,
  veeValidate_U1l0qzVbBC,
  vueDatepicker_xPbZ7qTVbL,
  vueDompurifyHtml_PZ39sa3f1y,
  vueToastification_TGgIjy9mOG
]