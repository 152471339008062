import { default as apothekers_45controleteVbjBg7bPMeta } from "/opt/atlassian/pipelines/agent/build/pages/apothekers-controle.vue?macro=true";
import { default as bestanden_45inladenqR1fHzCAyyMeta } from "/opt/atlassian/pipelines/agent/build/pages/bestanden-inladen.vue?macro=true";
import { default as bestellingencRrtR4kvnTMeta } from "/opt/atlassian/pipelines/agent/build/pages/bestellingen.vue?macro=true";
import { default as controle_45levering4cVZ2Vk0JUMeta } from "/opt/atlassian/pipelines/agent/build/pages/controle-levering.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as inloggen7sAPD1tvjiMeta } from "/opt/atlassian/pipelines/agent/build/pages/inloggen.vue?macro=true";
import { default as klantprofieltOgbNAJATtMeta } from "/opt/atlassian/pipelines/agent/build/pages/klantprofiel.vue?macro=true";
import { default as leveringen_45overzichtosZUgmsfQDMeta } from "/opt/atlassian/pipelines/agent/build/pages/leveringen-overzicht.vue?macro=true";
import { default as nazendingen4DxNFcTK6EMeta } from "/opt/atlassian/pipelines/agent/build/pages/nazendingen.vue?macro=true";
import { default as parallel_45bestellenLQH5qJA9OVMeta } from "/opt/atlassian/pipelines/agent/build/pages/parallel-bestellen.vue?macro=true";
import { default as printenws5zocMxc7Meta } from "/opt/atlassian/pipelines/agent/build/pages/printen.vue?macro=true";
export default [
  {
    name: "apothekers-controle",
    path: "/apothekers-controle",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/apothekers-controle.vue").then(m => m.default || m)
  },
  {
    name: "bestanden-inladen",
    path: "/bestanden-inladen",
    meta: bestanden_45inladenqR1fHzCAyyMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/bestanden-inladen.vue").then(m => m.default || m)
  },
  {
    name: "bestellingen",
    path: "/bestellingen",
    meta: bestellingencRrtR4kvnTMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/bestellingen.vue").then(m => m.default || m)
  },
  {
    name: "controle-levering",
    path: "/controle-levering",
    meta: controle_45levering4cVZ2Vk0JUMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/controle-levering.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexOD9t3F2bSJMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inloggen",
    path: "/inloggen",
    meta: inloggen7sAPD1tvjiMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/inloggen.vue").then(m => m.default || m)
  },
  {
    name: "klantprofiel",
    path: "/klantprofiel",
    meta: klantprofieltOgbNAJATtMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/klantprofiel.vue").then(m => m.default || m)
  },
  {
    name: "leveringen-overzicht",
    path: "/leveringen-overzicht",
    meta: leveringen_45overzichtosZUgmsfQDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/leveringen-overzicht.vue").then(m => m.default || m)
  },
  {
    name: "nazendingen",
    path: "/nazendingen",
    meta: nazendingen4DxNFcTK6EMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/nazendingen.vue").then(m => m.default || m)
  },
  {
    name: "parallel-bestellen",
    path: "/parallel-bestellen",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/parallel-bestellen.vue").then(m => m.default || m)
  },
  {
    name: "printen",
    path: "/printen",
    meta: printenws5zocMxc7Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/printen.vue").then(m => m.default || m)
  }
]